"use client"
import React, { useState, useEffect, useMemo, memo, useCallback } from "react";
import StarRatings from 'react-star-ratings';
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
  InfoWindow
} from "@react-google-maps/api";
import mapStyle from "./data/map_style.json";
import io from "socket.io-client";
import defaultProfile from "./assets/user.jpeg";
import axios from "axios";
import styles from "./styles/MapPage.module.css";
import {
  Star,
  EmojiTransportation,
  DirectionsCar,
  KeyboardDoubleArrowDown,
  PinDrop,
} from "@mui/icons-material";
import { useParams } from "react-router-dom/dist";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const defaultCenter = {
  lat: -1.28333,
  lng: 36.81667
};

const MemoizedGoogleMap = memo(
  ({ position, path,dest, actualPath,setSelectedDriver,selectedDriver,pickup,detail ,center, options,onLoad ,onUnmount}) => (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center || defaultCenter}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={12}
      options={{ 
        ...options,
        styles: mapStyle,
        gestureHandling: "cooperative"
      }}
    > 
      {pickup && <Marker pickup={pickup}/>} 
      {dest && <Marker position={dest} icon={{
                          url: "/images/location.png",
                          scaledSize: new window.google.maps.Size(30, 30),
                          origin: new window.google.maps.Point(0, 0),
                          anchor: new window.google.maps.Point(15, 15),
                        
            }}/>}

      {position && (
        <Marker
        onClick={()=>{setSelectedDriver(detail)}}
          position={position}
          icon={{
            url: "/images/car4.png",
            scaledSize: new window.google.maps.Size(50, 50),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
            rotation: position?.bearing || 0,
          }}
        />
      )}
      {path && path?.length > 0 && <Marker position={path[0]} />}
      {path && path?.length > 0 && (
        <Polyline path={path} options={{ strokeColor: "#e2b880" }} />
      )}
      {actualPath && actualPath?.length > 0 && (
        <Polyline path={actualPath} options={{ strokeColor: "#000000" }} />
      )}
    </GoogleMap>
  )
);

const socketUrl = `${process.env.REACT_APP_SOCKET}`;
function MapPage() {
  const [actualPath, setActualPath] = useState([]);
  const [position, setPosition] = useState();
  const [pickup,setPickup] = useState(defaultCenter);
  const [dest,setDest] = useState(defaultCenter);
  const [center, setCenter] = useState(defaultCenter);
  const [path, setPath] = useState([]);
  const [open, setOpen] = useState(true);
  const [detail, setDetail] = useState(null);
  const [map, setMap] = useState(null);
  const [selectedDriver,setSelectedDriver] = useState(null);
  // const apiUrl = "https://api.hesedjourneys.com/accounts/v1";
  

  const {tripId} = useParams();
  const Id = tripId;
  console.log({tripId});
  
  const getInitials = (str) => {
    const results = [];
    const wordArray = str?.split(" ");
    for (let i = 0; i < 2 && i < wordArray?.length; i++) {
      results.push(wordArray[i][0]);
    }
    return results.join("");
  };
  console.log({detail});
  

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP,
  });
  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_TRIPS}/trips/trip/${tripId}`);
        if(res?.data?.status?.message === "Success"){   
          const data = res?.data?.data;                
          if(data?.status?.name === "COMPLETED" || data?.status?.name==="START" ){         
            const newPath = data?.costPath?.flat().map(point =>({lat:point?.latitude,lng:point?.longitude}));
            setPath(newPath);         
            setDest({lat:data?.destination?.latitude ,lng:data?.destination?.longitude} || defaultCenter)
            setCenter(path?.length > 0 && {lat:path[0]?.latitude,lng:path[0]?.longitude}|| defaultCenter)
          }
          setDetail(res?.data?.data);
        }       
      } catch (error) {
        console.error("Failed to fetch trip details:", error);
      }
    };
    getDetails();
  }, [tripId]);

  useEffect(() => {
    if (path.length > 0) {
      console.log("Updated Path:", path);
    }
  }, [path]); 

  const onLoad = useCallback(
    function callback(map) {
      if (!path || path?.length === 0) return;
      const bounds = new window.google.maps.LatLngBounds();
      path?.forEach((driver) => {
        bounds.extend({ lat: driver?.latitude, lng: driver?.longitude } || defaultCenter);
      });    
        map.fitBounds(bounds);
        new window.google.maps.event.addListenerOnce(map, "bounds_changed", function () {
          const currentZoom = map.getZoom();
          if (currentZoom > 15) {
            map.setZoom(15);
          }
        });
      setMap(map);
    },
    [path]
  );
  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    console.log({effect:socketUrl});
    
    const socket = io("https://sockets.hesedjourneys.com/", {
      transports: ["websocket"],
      autoConnect: false,
    });

    socket.connect();

    // Connect to the socket server
    // const socket = io(process.env.REACT_APP_SOCKET);
    // console.log(socket)
    // Handle connection error
    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
      // Display an error message to the user
      // alert("Failed to connect to the server!!");
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    // Listen to Cordinates events for the specific trip ID
    socket.on(tripId, (Cordinates) => {
      console.log("Cordinates:", Cordinates);
      // Process the Cordinates data here
      const { payload } = Cordinates;
      if (payload) {
        const { projected, actual, position } = payload;

        console.log({projected});
        console.log({actual});
        
        if (projected && projected.path && projected.path.length > 0) {
          const validPath = projected.path.filter(
            (point) =>
              typeof point.latitude === "number" &&
              typeof point.longitude === "number" &&
              isFinite(point.latitude) &&
              isFinite(point.longitude)
          );
          const mappedPath = validPath.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
          }));
          setPath(mappedPath);
          const firstValidPoint = mappedPath[0];
          if (firstValidPoint) {
            setCenter(firstValidPoint);
          } else {
            setCenter(defaultCenter);
          }
        } else {
          setPath([]);
          setCenter(defaultCenter);
        }
        if (actual && actual.length > 0) {
          const validActualPath = actual.filter(
            (point) =>
              typeof point.latitude === "number" &&
              typeof point.longitude === "number" &&
              isFinite(point.latitude) &&
              isFinite(point.longitude)
          );
          const mappedActualPath = validActualPath.map((point) => ({
            lat: point.latitude,
            lng: point.longitude ,
          }));
          setActualPath(mappedActualPath);
        } else {
          setActualPath([]);
        }
        if (position) {
          if (
            typeof position.latitude === "number" &&
            typeof position.longitude === "number" &&
            isFinite(position.latitude) &&
            isFinite(position.longitude)
          ) {
            setPosition({ lat: position?.latitude, lng: position?.longitude });
          } else {
            setPosition(defaultCenter);
          }
        } else {
          setPosition(defaultCenter);
        }
      }
    });

    // Join room corresponding to trip ID
    socket.emit("join_trip", { tripId });

    // Clean up on component unmount
    return () => {
      socket.disconnect();
    };
  }, [path,actualPath]);

  const memoizedMapOptions = useMemo(() => ({ styles: mapStyle }), []);
  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);


  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }  
  return (
    <div className={styles.container}>
      <div className={styles.mapContainer}>
        <MemoizedGoogleMap
          position={position}
          path={path || []}
          actualPath={actualPath || []}
          onLoad={onLoad}
          onUnmount={onUnmount}
          selectedDriver={selectedDriver}
          center={center}
          pickup={path[0] || defaultCenter}
          dest={dest || defaultCenter}
          options={memoizedMapOptions}
          setSelectedDriver={setSelectedDriver}
          detail={detail}
        />
      </div>
      {(open && detail) && (
        <div className={styles.tripInfo}>
          <h3 style={{color: detail?.status?.name === "START" ?"#6EC631":"#000" }}>{detail?.status?.name === "START" ? "Active Trip":"Completed Trip" }</h3>
          <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}>
            <div>
              <p className={styles.tripCont}>{`${detail?.driver?.firstName} ${detail?.driver?.lastName} `}</p>
              <p className={styles.tripCont}>{`${detail?.vehicle?.make?.name} ${detail?.vehicle?.model?.name} `}</p>
              <p className={styles.tripCont}>{detail?.vehicle?.numberPlate}</p>
              <p className={styles.tripCont}><StarRatings rating={detail?.driver?.rating} starDimension="16px" starRatedColor="#ffa534" starSpacing="0px"/></p>           
            </div>
            <div>
              <img src={detail?.driver?.imageUrl ?`${process.env.REACT_APP_IMAGE_DOMAIN}?filename=${detail?.driver?.imageUrl}`:defaultProfile} style={{width:"70px",height:"70px",borderRadius:"100%", objectFit:"contain", marginTop:"-1rem"}}  />
            </div>
          </div>
          <div>
            <p style={{fontSize:"14px"}}><span style={{fontWeight:"550"}}>Pickup:</span> {detail?.pickup?.name}</p>
            <p style={{marginTop:"-0.6rem",fontSize:"14px"}}><span style={{fontWeight:"550"}}>Destination:</span> {detail?.destination?.name}</p>  
          </div> 
        </div>
      )}
      <div className={styles.mobileFooter}>
        {!open && (
          <img
            src="./images/chevron-up.svg"
            alt=""
            onClick={handleToggleOpen}
          />
        )}
        {open && (
          <img
            src="./images/chevron-down.svg"
            alt=""
            onClick={handleToggleOpen}
          />
        )}
      </div>
    </div>
  );
}

export default MapPage;
